import React, { useState, useEffect } from "react";
import {
  PlusIcon,
  ReLoadIcon,
  DropDownIcon,
  HorzontalMenuIcon,
  WarnningIcon,
  DelIcon,
  PropertiesIcon,
  UpIcon,
} from "../Icons";

import AddNewCurrencie from "../components/AddNewCurrencie";
import Toggle from "../components/Toggle";
import Modal from "../components/Modal";
import { useAuthContext } from "../context/AuthContext";
import axios from "axios";
import Spinner from "../components/Spinner/Spinner";
import EditIcon from "../Icons/EditIcon";
import { ToastContainer, toast } from "react-toastify";
import UpdateCurrency from "../components/UpdateCurrency";
import { API_URL } from '../config';

const CryptoCurrencies = () => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [hide, setHide] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAsset, setCurrentAsset] = useState();
  const [statusData, setStatusData] = useState([
    { id: 1, title: "Swap" },
    { id: 2, title: "Swap 1" },
    { id: 3, title: "Swap 2" },
  ]);
  const { currentUser, verifyJwt } = useAuthContext();
  const [selectedcompany, setselectedcompany] = useState();

  // headers for axios
  let headers = {
    Authorization: `Bearer ${currentUser}`,
  };

  const [cryptoVal, setCrytoVal] = useState([]);

  // get asset list
  const getAssetsList = async () => {
    setIsLoading(true);

    try {
      const res = await axios.get(
        `${API_URL}/admindashboard/assets/list`,
        { headers: headers }
      );
      let checkExpiry = verifyJwt(res?.data);
      if (checkExpiry) {
        setCrytoVal(res?.data?.data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log("e===>",e);
      setIsLoading(false);
    }
  };

  // delete asset
  const handleDeleteAsset = async (id) => {
    setIsLoading(true);

    try {
      const res = await axios.post(
        `${API_URL}/admindashboard/assets/delete`,
        { id },
        { headers: headers }
      );
      let checkExpiry = verifyJwt(res?.data);
      if (checkExpiry) {
        const getData = await getAssetsList();
        toast.success(res.data?.data, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  // handle edit asset
  const handleEditAsset = (asset) => {
    setCurrentAsset(asset);
    setOpenUpdate(true);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
    getAssetsList();
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading && <Spinner />}
      <div className="crpto-page flex flex-col">
        <div className="wrapWidth wrap flex flex-col">
          <div className="pg-hder flex aic">
            <div className="page-tag">Cryptocurrencies</div>
            <div className="right-side flex aic">
              <div className="actions flex aic">
                {/* <div className="dropDown flex aic jc flex-col rel">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide(!hide);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng flex aic font s14 b4"
                            placeholder="Swap"
                          >
                            {selectedcompany ? selectedcompany.title : "Swap"}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {statusData.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide(!hide);
                            setselectedcompany(item);
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
                <div
                  className="btn-load button flex aic"
                  onClick={getAssetsList}
                >
                  <div className="ico">
                    <ReLoadIcon />
                  </div>
                  <div className="btn-lbl">Reload</div>
                </div>
                <div
                  className="btn-new button flex aic"
                  onClick={() => setOpen(true)}
                >
                  <div className="ico">
                    <PlusIcon />
                  </div>
                  <div className="btn-lbl">New Currency</div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-table flex ">
            <div className="table-blk flex ">
              <div className="crypto-tbl flex flex-col ">
                <div className="tbl-row flex aic">
                  <div className="row-item flex">Code</div>
                  <div className="row-item flex">Name</div>
                  <div className="row-item flex">Issuer</div>
                  <div className="row-item flex">Ledger</div>
                  <div className="row-item flex">Network</div>
                  <div className="row-item flex">Actions</div>

                  {/* <div className="row-item flex">Logo</div>
                <div className="row-item flex">Created At</div>
                <div className="row-item flex">Visible</div>
                <div className="row-item flex">Deposit</div>
                <div className="row-item flex">Withdrwal</div>
                <div className="row-item flex">Status</div> */}
                </div>
                {cryptoVal?.map((item, i) => (
                  <div className="tbl-row flex aic">
                    <div className="row-item flex">{item?.asset_code}</div>
                    <div className="row-item flex">{item?.asset_name} </div>
                    <div className="w-32 !max-w-12 overflow-scroll">
                      {item?.asset_issuer}
                    </div>
                    <div className="row-item flex">
                      {item?.ledger ? item.ledger.toUpperCase() : ""}
                    </div>

                    <div className="row-item flex">
                      {item?.is_live_net === true ? "Livenet" : "Testnet"}
                    </div>
                    <div className="row-item flex">
                      <div className="r-actions flex aic">
                        <div
                          className="ico icon-del flex aic jc"
                          title="Delete Asset"
                          onClick={() => handleDeleteAsset(item?.id)}
                        >
                          <DelIcon />
                        </div>
                        <div
                          className="ico icon-up flex aic jc"
                          title="Edit Asset"
                          onClick={() => handleEditAsset(item)}
                        >
                          <PropertiesIcon />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row-item flex">
                    <img src={item.logo} className="nft-logo" />
                  </div>
                  <div className="row-item flex">{item.created_at}</div>
                  <div className="row-item flex">
                    <Toggle />
                  </div>
                  <div className="row-item flex">
                    <Toggle />
                  </div>
                  <div className="row-item flex">
                    <Toggle />
                  </div>
                  <div className="row-item flex">
                    <HorzontalMenuIcon />
                  </div> */}
                  </div>
                ))}
                <div className="tbl-row flex aic">
                  <div className="row-item flex"></div>
                  <div className="row-item flex"></div>
                  <div className="row-item flex"></div>
                  <div className="row-item flex"></div>
                  <div className="row-item flex"></div>
                  <div className="row-item flex"></div>
                  <div className="row-item flex"></div>
                </div>
              </div>
            </div>
          </div>
          <Modal open={open} onClose={() => setOpen(false)}>
            <AddNewCurrencie
              open={open}
              setOpen={setOpen}
              setIsLoading={setIsLoading}
              getAssetsList={getAssetsList}
            />
          </Modal>
          <Modal open={openUpdate} onClose={() => setOpenUpdate(false)}>
            <UpdateCurrency
              open={openUpdate}
              currentAsset={currentAsset}
              setOpen={setOpenUpdate}
              setIsLoading={setIsLoading}
              getAssetsList={getAssetsList}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CryptoCurrencies;
