import React from 'react';
import './Spinner.css';


const Spinner = () => {
  return (
    <div className="loader_wrapper">
      <div className='loader'>

      </div>
    </div>

  )
}

export default Spinner