import React from "react";

function TransIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 13 16"
    >
      <path
        fill="#fff"
        d="M11.52 0H1.28C.576 0 0 .554 0 1.23v13.54C0 15.445.576 16 1.28 16h10.24c.704 0 1.28-.554 1.28-1.23V1.23C12.8.555 12.224 0 11.52 0zM4.48 3.692h3.84c.384 0 .64.246.64.616 0 .369-.256.615-.64.615H4.48c-.384 0-.64-.246-.64-.615 0-.37.256-.616.64-.616zm5.12 7.385H3.2c-.384 0-.64-.246-.64-.616 0-.369.256-.615.64-.615h6.4c.384 0 .64.246.64.615 0 .37-.256.616-.64.616zM9.6 8H3.2c-.384 0-.64-.246-.64-.615 0-.37.256-.616.64-.616h6.4c.384 0 .64.246.64.616 0 .369-.256.615-.64.615z"
      ></path>
    </svg>
  );
}

export default TransIcon;
