import React from "react";

function ReLoadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="17"
      fill="none"
      viewBox="0 0 13 17"
    >
      <path
        stroke="#14192D"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        d="M8.763 5.11s.861-.423-2.263-.423a5.657 5.657 0 105.657 5.656"
      ></path>
      <path
        stroke="#14192D"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        d="M6.5 2l2.828 2.828L6.5 7.657"
      ></path>
    </svg>
  );
}

export default ReLoadIcon;
