import React from "react";

function TrackingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill="#fff"
        d="M7.451 0a5.64 5.64 0 00-5.633 5.633c0 1.26.431 2.5 1.216 3.492l4.418 5.554 4.42-5.557a5.648 5.648 0 001.213-3.49A5.64 5.64 0 007.452 0zm0 7.733c-1.157 0-2.1-.942-2.1-2.1 0-1.158.943-2.1 2.1-2.1 1.159 0 2.1.942 2.1 2.1 0 1.158-.941 2.1-2.1 2.1z"
      ></path>
      <path
        fill="#fff"
        d="M7.538 15.518c2.48-.007 4.476-.345 4.476-.76 0-.346-1.39-.638-3.291-.73l.382-.48c3.319.125 5.796.62 5.796 1.21 0 .686-3.335 1.242-7.45 1.242S0 15.444 0 14.758c0-.59 2.478-1.085 5.796-1.21l.382.48c-1.9.092-3.29.384-3.29.73 0 .416 1.996.753 4.476.76h.174z"
      ></path>
    </svg>
  );
}

export default TrackingIcon;
