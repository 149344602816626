import React from "react";

function PropertiesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill="#8F8F8F"
        d="M14.216 6.625L7.786.7c-.21-.208-.566-.208-.804 0L.58 6.625c-.178.148-.238.416-.148.655.089.238.297.357.536.357h1.845v5.805c0 .626.506 1.102 1.102 1.102h6.877c.625 0 1.102-.506 1.102-1.102V7.637h1.846a.588.588 0 00.535-.357c.09-.209.12-.506-.06-.655h.001zM8.291 8.977v.476H6.833v.357H8.29v.477h-1.34c.09.178.21.327.388.416.149.09.327.15.536.15.387 0 .684-.15.952-.447l.536.595a2.055 2.055 0 01-.714.536c-.268.12-.536.179-.834.179-.447 0-.834-.12-1.161-.387-.328-.268-.536-.596-.685-1.043h-.565V9.81h.506v-.357h-.477v-.476H6c.12-.447.327-.804.655-1.072.327-.268.715-.387 1.19-.387.596 0 1.073.238 1.49.714l-.507.685c-.297-.357-.625-.506-1.012-.506-.446 0-.744.208-.893.595h1.37v-.03z"
      ></path>
    </svg>
  );
}

export default PropertiesIcon;
