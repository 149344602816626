import React from "react";

function WarnningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#A6AEBB"
        strokeWidth="2"
        d="M3.97 14.47l10.5-10.5M16.5 9a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
      ></path>
    </svg>
  );
}

export default WarnningIcon;
