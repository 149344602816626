import React from "react";

function GeneralIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#4B9A2A"
        d="M7 0a7 7 0 100 14A7 7 0 007 0zm.455 10.668h-.91v-5h.91v5zM6.979 4.82c-.33 0-.547-.248-.547-.558 0-.31.227-.568.568-.568.33 0 .558.248.558.568 0 .31-.217.558-.579.558z"
      ></path>
    </svg>
  );
}

export default GeneralIcon;
