import React from "react";

function UsersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="#fff"
        d="M12.596 10.644l-.032-.124c-.863-2.126-2.59-3.57-4.572-3.57-1.982 0-3.708 1.444-4.571 3.55l-.032.123c-.016.041-.016.103-.016.165v.516c0 .31.208.578.448.578h8.36c.239 0 .447-.268.447-.578v-.516a.472.472 0 00-.032-.144zM2.637 10.045l.016-.062c.496-1.197 1.2-2.167 2.03-2.868a2.887 2.887 0 00-1.486-.413c-1.359 0-2.558 1.011-3.15 2.476l-.031.083A.234.234 0 000 9.364v.35c0 .228.144.393.304.393h2.301l.032-.062zM4.684 3.524c0 1.06-.666 1.919-1.487 1.919-.82 0-1.486-.86-1.486-1.92s.665-1.919 1.486-1.919 1.487.86 1.487 1.92zM13.347 10.045l-.016-.041c-.496-1.197-1.199-2.167-2.03-2.869.447-.268.959-.412 1.486-.412 1.375 0 2.558.99 3.165 2.455l.032.083a.282.282 0 01.016.103v.351c0 .227-.144.392-.304.392H13.38l-.032-.062zM14.274 3.524c0 1.06-.666 1.919-1.487 1.919-.82 0-1.486-.86-1.486-1.92s.665-1.919 1.486-1.919 1.487.86 1.487 1.92zM10.277 3.09c0 1.641-1.03 2.972-2.301 2.972-1.272 0-2.302-1.33-2.302-2.972 0-1.641 1.03-2.972 2.302-2.972 1.27 0 2.301 1.33 2.301 2.972z"
      ></path>
    </svg>
  );
}

export default UsersIcon;
