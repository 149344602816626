import React, { useState, useEffect } from "react";
import {
  CrossIcon,
  GeneralIcon,
  DepositIcon,
  WidthDrwal,
  PropertiesIcon,
  DropDownIcon,
  PlusIcon,
} from "../Icons";
import Toggle from "./Toggle";
import axios from "axios";
import { useAuthContext } from "../context/AuthContext";
import Spinner from "./Spinner/Spinner";
import { API_URL } from '../config';

const AddNewCurrencie = ({ setOpen, setIsLoading, getAssetsList }) => {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [statusData, setStatusData] = useState([
    { id: 1, title: "Swap" },
    { id: 2, title: "Swap 1" },
    { id: 3, title: "Swap 2" },
  ]);
  const [selectedcompany, setselectedcompany] = useState();
  const [selectedcompany2, setselectedcompany2] = useState();
  const [step, setStep] = useState("General");
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const mapList = [
    { lbl: "General", icon: <GeneralIcon /> },
    { lbl: "Deposit", icon: <DepositIcon /> },
    { lbl: "Withdrawal", icon: <WidthDrwal /> },
    { lbl: "Properties", icon: <PropertiesIcon /> },
  ];
  const { currentUser, verifyJwt } = useAuthContext();

  // headers for axios
  let headers = {
    Authorization: `Bearer ${currentUser}`,
  };

  const [formData, setFormData] = useState({
    asset_code: "",
    asset_issuer: "",
    asset_name: "",
    is_live_net: true,
    ledger: "xrp",
  });
  const [isError, setIsError] = useState(false);

  // handle add currency
  const handleAddCurrency = async () => {
    setIsError(false);
    if (
      formData.asset_code !== "" &&
      formData.asset_issuer !== "" &&
      formData?.asset_name !== "" &&
      formData?.ledger !== ""
    ) {
      setIsError(false);

      setIsLoading(true);
      try {
        const res = await axios.post(
          `${API_URL}/admindashboard/assets/add`,
          { ...formData },
          { headers: headers }
        );
        const data = res.data;
        let checkExpiry = verifyJwt(res?.data);
        if (checkExpiry) {
          getAssetsList();
        }
        setOpen(false);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    } else {
      setIsError(true);
    }
  };

  const Next = () => {
    if (step === "General") {
      setStep("Deposit");
      setStep2(true);
    }
    if (step == "Deposit") {
      setStep("Withdrawal");
      setStep3(true);
    }
    if (step == "Withdrawal") {
      setStep("Properties");
      setStep4(true);
    }

    // if (step4 === "Properties") {
    //   setStep4("Properties");
    //   console.log("Step 4");
    // }
  };

  const [addProp, setAddProp] = useState([
    { txt: { value: "" } },
    { txt: { value: "" } },
    { txt: { value: "" } },
  ]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
      setHide2(false);
    });
  }, []);

  return (
    <>
      <div className="add-currencie flex flex-col">
        <div className=" wrap flex flex-col">
          <div className="add-hdr flex aic">
            <div className="lbl">New Currency</div>
            <div className="close-side flex">
              <div
                className="icon-close flex aic jc"
                onClick={(e) => setOpen(false)}
              >
                <CrossIcon />
              </div>
            </div>
          </div>
          <div className="data-block flex">
            <div className="left flex flex-col">
              <div className="road-map flex flex-col">
                {/* {mapList.map((item, index) => (
                <div
                  className={`item flex aic ${
                    step === "General" ? "active" : ""
                  } ${step === "Deposit" ? "active" : ""} ${
                    step === "Withdrawal" ? "active" : ""
                  } ${step === "Properties" ? "active" : ""}`}
                >
                  <div className="item-lbl flex">{item.lbl}</div>
                  <div className="item-icon flex aic jc ">{item.icon}</div>
                </div>
              ))} */}
                <div
                  className={`item flex aic ${
                    step === "General" ? "active" : ""
                  }`}
                >
                  <div className="item-lbl flex">General</div>
                  <div className="item-icon flex aic jc ">
                    <GeneralIcon />
                  </div>
                </div>
                {/* <div className={`item flex aic ${step2 ? "active" : ""}`}>
                <div className="item-lbl flex">Deposit</div>
                <div className="item-icon flex aic jc ">
                  <DepositIcon />
                </div>
              </div>
              <div className={`item flex aic ${step3 ? "active" : ""}`}>
                <div className="item-lbl flex">Withdrawal</div>
                <div className="item-icon flex aic jc ">
                  <WidthDrwal />
                </div>
              </div>
              <div className={`item flex aic ${step4 ? "active" : ""}`}>
                <div className="item-lbl flex">Properties</div>
                <div className="item-icon flex aic jc ">
                  <PropertiesIcon />
                </div>
              </div> */}
              </div>
            </div>
            {step === "General" && (
              <div className="right flex flex-col">
                {/* <div className="field flex flex-col">
                <div className="f-lbl">Visible</div>
                <Toggle />
              </div> */}
                <div className="field flex flex-col">
                  <div className="f-lbl">Name</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Name"
                    value={formData?.asset_name}
                    onChange={(e) =>
                      setFormData({ ...formData, asset_name: e.target.value })
                    }
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Code</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Code"
                    value={formData?.asset_code}
                    onChange={(e) =>
                      setFormData({ ...formData, asset_code: e.target.value })
                    }
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Issuer</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Issuer"
                    value={formData?.asset_issuer}
                    onChange={(e) =>
                      setFormData({ ...formData, asset_issuer: e.target.value })
                    }
                  />
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">Ledger</div>
                  <select
                    type="select"
                    className="txt cleanbtn"
                    placeholder="Network"
                    value={formData?.ledger}
                    required={true}
                    onChange={(e) =>
                      setFormData({ ...formData, ledger: e.target.value })
                    }
                  >
                    <option value={"xrp"}>XRP Ledger</option>
                    <option value={"xlm"}>Stellar Network</option>
                  </select>
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">For Livenet?</div>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setFormData({ ...formData, is_live_net: e.target.checked })
                    }
                    checked={formData?.is_live_net}
                  />
                </div>

                {isError && (
                  <div className="field flex flex-col">
                    <p className="isValid">
                      Please fill all the required fields
                    </p>
                  </div>
                )}
                {/* <div className="field flex flex-col">
                <div className="f-lbl">Symbol</div>
                <div className="dropDown flex aic jc flex-col rel">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide(!hide);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng flex aic font s14 b4"
                            placeholder="Swap"
                          >
                            {selectedcompany ? selectedcompany.title : "Swap"}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {statusData.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide(!hide);
                            setselectedcompany(item);
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="f-lbl">Type</div>
                <input
                  type="text"
                  className="txt cleanbtn"
                  placeholder="Type"
                />
              </div>
              <div className="field flex flex-col">
                <div className="f-lbl">Subunits</div>
                <input
                  type="text"
                  className="txt cleanbtn"
                  placeholder="Subunits"
                />
              </div>
              <div className="field flex flex-col">
                <div className="f-lbl">Percision</div>
                <input
                  type="text"
                  className="txt cleanbtn"
                  placeholder="Percision"
                />
              </div>
              <div className="field flex flex-col">
                <div className="f-lbl">Blockchain Key</div>
                <div className="dropDown flex aic jc flex-col rel">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide2(!hide2);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng flex aic font s14 b4"
                            placeholder="Swap"
                          >
                            {selectedcompany2 ? selectedcompany2.title : "Swap"}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide2 ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {statusData.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide2(!hide2);
                            setselectedcompany2(item);
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="f-lbl">Percision</div>
                <input
                  type="text"
                  className="txt cleanbtn"
                  placeholder="Percision"
                />
              </div>
              <div className="field flex flex-col">
                <div className="f-lbl">Icon URL</div>
                <input
                  type="text"
                  className="txt cleanbtn"
                  placeholder="Icon URL"
                />
              </div> */}
              </div>
            )}
            {step === "Deposit" && (
              <div className="right flex flex-col">
                <div className="field flex flex-col">
                  <div className="f-lbl">Deposit</div>
                  <Toggle />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Deposit Fee</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Deposit Fee"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Mini deposit Amount</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Mini deposit Amount"
                  />
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">Mini Collection Amount</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Mini Collection Amount"
                  />
                </div>
              </div>
            )}
            {step === "Withdrawal" && (
              <div className="right flex flex-col">
                <div className="field flex flex-col">
                  <div className="f-lbl">Withdrawal</div>
                  <Toggle />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Withdraw Fee</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Withdraw Fee"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Mini Withdraw Amount</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Mini Withdraw Amount"
                  />
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">24h Withdraw Limit</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="24h Withdraw Limit"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">24h Withdraw Limit</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="24h Withdraw Limit"
                  />
                </div>
              </div>
            )}
            {step === "Properties" && (
              <div className="right flex flex-col">
                <div className="btn-row flex flex-col">
                  <button
                    className="btn-add-propertiy button flex aic jc"
                    onClick={(e) =>
                      setAddProp([...addProp, { txt: { value: "" } }])
                    }
                  >
                    <div className="ico flex aic jc">
                      <PlusIcon />
                    </div>
                    <div className="btn-lbl">Add Propertiy</div>
                  </button>
                </div>
                {addProp.map((item, index) => (
                  <div className="row flex aic">
                    <div className="row-txt flex flex-col">
                      {/* <div className="f-lbl">erc20_contract-address</div> */}
                      <input
                        type="text"
                        className="txt cleanbtn"
                        placeholder="erc20_contract-address"
                      />
                    </div>
                    <div className="row-btn flex aic jc">
                      <div className="btn-remove button">Remove</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="action flex aic justify-end">
            <div className="btn button" onClick={handleAddCurrency}>
              {/* {step4 ? " Create Currency" : "Next"} */}
              Create Currency
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewCurrencie;
