import React from "react";

function DropDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      fill="none"
      viewBox="0 0 16 8"
    >
      <path
        stroke="#14192D"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 .5l7 7 7-7"
      ></path>
    </svg>
  );
}

export default DropDownIcon;
