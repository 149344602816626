import React, { useState, useEffect } from "react";
import {
  PlusIcon,
  ReLoadIcon,
  DropDownIcon,
  HorzontalMenuIcon,
} from "../Icons";

import AddNewBlockchains from "../components/AddNewBlockchains";
import Toggle from "../components/Toggle";
import Modal from "../components/Modal";

const Blockchains = () => {
  const [open, setOpen] = useState(false);
  const cryptoVal = [
    {
      id: "1",
      code: "BTC",
      name: "Bitcoin",
      logo: "./images/logo1.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "2",
      code: "ETH",
      name: "Ethereum",
      logo: "./images/logo2.png",
      created_at: "26.04.2022 15:29:05",
    },
    {
      id: "3",
      code: "USDT",
      name: "Tether",
      logo: "./images/logo3.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "4",
      code: "BNB",
      name: "Binance Coin",
      logo: "./images/logo4.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "5",
      code: "USDC",
      name: "USD Coin",
      logo: "./images/logo5.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "6",
      code: "XRP",
      name: "XRP",
      logo: "./images/logo6.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "7",
      code: "SOL",
      name: "Solana",
      logo: "./images/logo7.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "8",
      code: "LUNA",
      name: "Terra ",
      logo: "./images/logo8.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "9",
      code: "ADA",
      name: "Cardano ",
      logo: "./images/logo9.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "10",
      code: "AVAX",
      name: "Avalanche ",
      logo: "./images/logo10.png",
      created_at: "25.04.2022 15:29:05",
    },
    {
      id: "11",
      code: "DOT",
      name: "Polkadot ",
      logo: "./images/logo11.png",
      created_at: "25.04.2022 15:29:05",
    },
  ];

  return (
    <div className="blockchain-page flex flex-col">
      <div className="wrapWidth wrap flex flex-col">
        <div className="pg-hder flex aic">
          <div className="page-tag">Blockchains</div>
          <div className="right-side flex aic">
            <div className="actions flex aic">
              <div className="btn-load button flex aic">
                <div className="ico">
                  <ReLoadIcon />
                </div>
                <div className="btn-lbl">Reload</div>
              </div>
              <div
                className="btn-new button flex aic"
                onClick={(e) => setOpen(true)}
              >
                <div className="ico">
                  <PlusIcon />
                </div>
                <div className="btn-lbl">Blockchains</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-blk flex">
          <div className="crypto-tbl flex flex-col">
            <div className="tbl-row flex aic">
              <div className="row-item flex">ID</div>
              <div className="row-item flex">Key</div>
              <div className="row-item flex">Name</div>
              <div className="row-item flex">Client</div>
              <div className="row-item flex">Height</div>
              <div className="row-item flex">Created At</div>
              <div className="row-item flex">Status</div>
              <div className="row-item flex"></div>
            </div>
            {cryptoVal.map((item, i) => (
              <div className="tbl-row flex aic">
                <div className="row-item flex">{item.id}</div>
                <div className="row-item flex">{item.code} </div>
                <div className="row-item flex">{item.name}</div>
                <div className="row-item flex">parity</div>
                <div className="row-item flex">7655008</div>
                <div className="row-item flex">{item.created_at}</div>
                <div className="row-item flex">
                  <Toggle />
                </div>
                <div className="row-item flex">
                  <HorzontalMenuIcon />
                </div>
              </div>
            ))}
            <div className="tbl-row flex aic">
              <div className="row-item flex"></div>
              <div className="row-item flex"></div>
              <div className="row-item flex"></div>
              <div className="row-item flex"></div>
              <div className="row-item flex"></div>
              <div className="row-item flex"></div>
              <div className="row-item flex"></div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <AddNewBlockchains open={open} setOpen={setOpen} />
      </Modal>
    </div>
  );
};

export default Blockchains;