import React from "react";

function DelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="#A6AEBB"
        d="M12.894 2.643H9.679v-.804A1.34 1.34 0 008.34.5H5.662a1.34 1.34 0 00-1.34 1.34v.803H1.108a.536.536 0 000 1.071h.57l.635 10.21c.048.899.737 1.576 1.607 1.576h6.161c.875 0 1.55-.662 1.607-1.574l.636-10.212h.57a.536.536 0 000-1.071zM4.877 13.357h-.019a.536.536 0 01-.536-.516l-.268-7.5a.536.536 0 111.072-.039l.268 7.5a.536.536 0 01-.517.555zm2.66-.536a.536.536 0 11-1.072 0v-7.5a.536.536 0 111.072 0v7.5zM8.608 2.643H5.394v-.804a.265.265 0 01.268-.268H8.34a.265.265 0 01.268.268v.804zM9.679 12.84a.535.535 0 01-.535.516h-.02a.536.536 0 01-.516-.555l.268-7.5a.536.536 0 111.071.039l-.268 7.5z"
      ></path>
    </svg>
  );
}

export default DelIcon;
