import React from "react";

function BlockCaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M8 0L4 2v2L0 6v4l4 2v2l4 2 4-2v-2l4-2V6l-4-2V2L8 0zM4.5 3l3 1.5v1.25l-3-1.5V3zm7 0v1.25l-3 1.5V4.5l3-1.5zM.5 7l3 1.5V11l-3-1.5V7zm7 0v1.25l-3 1.5V8.5l3-1.5zm1 0l3 1.5v1.25l-3-1.5V7zm7 0v2.5l-3 1.5V8.5l3-1.5zm-11 4l3 1.5V15l-3-1.5V11zm7 0v2.5l-3 1.5v-2.5l3-1.5z"
      ></path>
    </svg>
  );
}

export default BlockCaIcon;
