import React, { useState, useEffect } from "react";
import Map from "../components/Map";
import { DropDownIcon } from "../Icons";
import VisitorsGraph from "../components/VisitorsGraph";

const Tracking = () => {
  const [hide, setHide] = useState(false);
  const [statusData, setStatusData] = useState([
    { id: 1, title: "Daily" },
    { id: 2, title: "Weekly" },
    { id: 3, title: "Monthly" },
  ]);
  const [selectedcompany, setselectedcompany] = useState();
  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);
  const reportsVal = [
    { val: "439", lbl: "Page Views", img: "./images/View.svg" },
    { val: "309", lbl: "Visitors", img: "./images/visitor.svg" },
    {
      val: "273",
      lbl: "First Time Visitors",
      img: "./images/firstVisitor.svg",
    },
    { val: "3", lbl: "User Online", img: "./images/online.svg" },
    { val: "1m 58s", lbl: "Average Visit Length", img: "./images/time.svg" },
    { val: "1.4", lbl: "Page Views Per Visit", img: "./images/page.svg" },
  ];
  return (
    <div className="tracking-page flex">
      <div className="wrapWidth wrap flex flex-col">
        <div className="page-hdr">
          {reportsVal.map((item, index) => (
            <div className="box flex jc flex-col">
              <div className="icon">
                <img src={item.img} className="ico-img" />
              </div>
              <div className="meta flex flex-col">
                <div className="val">{item.val}</div>
                <div className="lbl">{item.lbl}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="mid-sec">
          <div className="left flex flex-col">
            <div className="left-tag">General Stats</div>
            <div className="row-lists flex flex-col">
              <div className="row-item flex aic">
                <div className="le flex">Total Page Views</div>
                <div className="re flex">127,595</div>
              </div>
              <div className="row-item flex aic">
                <div className="le flex">Total Visitors</div>
                <div className="re flex">90,746</div>
              </div>
              <div className="row-item flex aic">
                <div className="le flex">Page Views Per Visit</div>
                <div className="re flex">1,41</div>
              </div>
              <div className="row-item flex aic">
                <div className="le flex">Last Hits Time</div>
                <div className="re flex">15:10:32 29 April 2022</div>
              </div>
            </div>
          </div>
          <div className="right flex flex-col">
            <div className="right-tag">Geo Location</div>
            <div className="map-box">
              <Map />
            </div>
          </div>
        </div>
        <div className="visitor-graph flex flex-col">
          <div className="chart-hdr flex aic">
            <div className="chart-tag">Transation Summary</div>
            <div className="chart-right flex aic">
              <div className="chart-options flex aic">
                <div className="chart-item flex aic">
                  <input type="checkbox" className="checkbox" />
                  <div className="lbl">First Time Visitor </div>
                </div>
                <div className="chart-item flex aic">
                  <input type="checkbox" className="checkbox" />
                  <div className="lbl">Visitors</div>
                </div>
                <div className="chart-item flex aic">
                  <input type="checkbox" className="checkbox" />
                  <div className="lbl">Page Views</div>
                </div>
                <div className="chart-item flex aic">
                  <input type="checkbox" className="checkbox" />
                  <div className="lbl">Average Online</div>
                </div>
              </div>
              <div className="dropDown flex aic jc flex-col rel">
                <div className="category flex aic">
                  <div
                    className="cbox cleanbtn flex aic rel"
                    onClick={(e) => {
                      e.stopPropagation();
                      setHide(!hide);
                    }}
                  >
                    <div className="slt flex aic">
                      <div className="unit-name flex aic font s14 b4">
                        <span
                          className="unit-eng flex aic font s14 b4"
                          placeholder="Weekly"
                        >
                          {selectedcompany ? selectedcompany.title : "Weekly"}
                        </span>
                      </div>
                    </div>

                    <div>
                      <DropDownIcon />
                    </div>
                  </div>
                </div>
                <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                  <div className="manue flex aic col anim">
                    {statusData.map((item, index) => (
                      <div
                        key={index}
                        className="slt flex aic"
                        onClick={(e) => {
                          setHide(!hide);
                          setselectedcompany(item);
                        }}
                      >
                        <div className="unit-name flex aic font s14 b4">
                          <span className="unit-eng flex aic font s14 b4">
                            {item.title}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="graph">
            <VisitorsGraph />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
