import React from "react";

function DashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#fff"
        d="M16 9.556v5.867a.801.801 0 01-.8.8H9.333a.801.801 0 01-.8-.8V9.556a.801.801 0 01.8-.8H15.2a.801.801 0 01.8.8zm-9.333-.8H.8a.801.801 0 00-.8.8v5.867a.801.801 0 00.8.8h5.867a.802.802 0 00.8-.8V9.556a.801.801 0 00-.8-.8zM15.2.223H9.333a.801.801 0 00-.8.8v5.866a.801.801 0 00.8.8H15.2a.801.801 0 00.8-.8V1.023a.801.801 0 00-.8-.8zm-8.533 0H.8a.801.801 0 00-.8.8v5.866a.801.801 0 00.8.8h5.867a.801.801 0 00.8-.8V1.023a.801.801 0 00-.8-.8z"
      ></path>
    </svg>
  );
}

export default DashboardIcon;
