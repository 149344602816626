import React from "react";

function UpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="#A6AEBB"
        d="M13.17 6.26a.22.22 0 00-.048-.136L8.864.588A.222.222 0 008.688.5c-.07 0-.14.033-.184.088L4.253 6.123a.223.223 0 00-.007.272c.06.08.17.112.265.075l2.818-1.114.04 7.393-6.54-.006V15.446h.006c.021.002 0 .054 0 .054H7.37l2.662-.006.007-10.138 2.818 1.114a.228.228 0 00.265-.075.227.227 0 00.048-.135z"
      ></path>
    </svg>
  );
}

export default UpIcon;
