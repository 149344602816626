import React, { useState, useEffect } from "react";
import { CrossIcon, GeneralIcon, PlusIcon } from "../Icons";
import Toggle from "./Toggle";
import axios from "axios";
import { useAuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../config";

const UpdateCurrency = ({
  setOpen,
  setIsLoading,
  getAssetsList,
  currentAsset,
}) => {
  const [step, setStep] = useState("General");
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);

  const { currentUser, verifyJwt } = useAuthContext();

  // headers for axios
  let headers = {
    Authorization: `Bearer ${currentUser}`,
  };

  const [formData, setFormData] = useState({
    asset_code: "",
    asset_issuer: "",
    asset_name: "",
    ledger: "",
  });

  const [isError, setIsError] = useState(false);

  // handle add currency
  const handleUpdateCurrency = async () => {
    setIsError(false);
    if (
      formData.asset_code !== "" &&
      formData.asset_issuer !== "" &&
      formData?.asset_name !== "" &&
      formData?.network !== ""
    ) {
      setIsError(false);

      setIsLoading(true);
      try {
        const res = await axios.post(
          `${API_URL}/admindashboard/assets/update`,
          { ...formData, id: currentAsset.id },
          { headers: headers }
        );
        const data = res.data;
        let checkExpiry = verifyJwt(res?.data);
        if (checkExpiry) {
          const data = await getAssetsList();
          toast.success(res.data?.data, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setOpen(false);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    } else {
      setIsError(true);
    }
  };

  const [addProp, setAddProp] = useState([
    { txt: { value: "" } },
    { txt: { value: "" } },
    { txt: { value: "" } },
  ]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
      setHide2(false);
    });
    setFormData(currentAsset);
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="add-currencie flex flex-col">
        <div className=" wrap flex flex-col">
          <div className="add-hdr flex aic">
            <div className="lbl">Update Currency</div>
            <div className="close-side flex">
              <div
                className="icon-close flex aic jc"
                onClick={(e) => setOpen(false)}
              >
                <CrossIcon />
              </div>
            </div>
          </div>
          <div className="data-block flex">
            <div className="left flex flex-col">
              <div className="road-map flex flex-col">
                <div
                  className={`item flex aic ${
                    step === "General" ? "active" : ""
                  }`}
                >
                  <div className="item-lbl flex">General</div>
                  <div className="item-icon flex aic jc ">
                    <GeneralIcon />
                  </div>
                </div>
              </div>
            </div>
            {step === "General" && (
              <div className="right flex flex-col">
                <div className="field flex flex-col">
                  <div className="f-lbl">Name</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Name"
                    value={formData?.asset_name}
                    onChange={(e) =>
                      setFormData({ ...formData, asset_name: e.target.value })
                    }
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Code</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Code"
                    value={formData?.asset_code}
                    onChange={(e) =>
                      setFormData({ ...formData, asset_code: e.target.value })
                    }
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Issuer</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Issuer"
                    value={formData?.asset_issuer}
                    onChange={(e) =>
                      setFormData({ ...formData, asset_issuer: e.target.value })
                    }
                  />
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">Ledger</div>
                  <select
                    type="select"
                    className="txt cleanbtn"
                    placeholder="Ledger"
                    value={formData?.ledger}
                    required={true}
                    onChange={(e) =>
                      setFormData({ ...formData, ledger: e.target.value })
                    }
                  >
                    <option value={"xrp"}>XRP Ledger</option>
                    <option value={"xlm"}>Stellar Network</option>
                  </select>
                </div>

                {isError && (
                  <div className="field flex flex-col">
                    <p className="isValid">
                      Please fill all the required fields
                    </p>
                  </div>
                )}
              </div>
            )}
            {step === "Deposit" && (
              <div className="right flex flex-col">
                <div className="field flex flex-col">
                  <div className="f-lbl">Deposit</div>
                  <Toggle />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Deposit Fee</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Deposit Fee"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Mini deposit Amount</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Mini deposit Amount"
                  />
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">Mini Collection Amount</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Mini Collection Amount"
                  />
                </div>
              </div>
            )}
            {step === "Withdrawal" && (
              <div className="right flex flex-col">
                <div className="field flex flex-col">
                  <div className="f-lbl">Withdrawal</div>
                  <Toggle />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Withdraw Fee</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Withdraw Fee"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">Mini Withdraw Amount</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Mini Withdraw Amount"
                  />
                </div>

                <div className="field flex flex-col">
                  <div className="f-lbl">24h Withdraw Limit</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="24h Withdraw Limit"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="f-lbl">24h Withdraw Limit</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="24h Withdraw Limit"
                  />
                </div>
              </div>
            )}
            {step === "Properties" && (
              <div className="right flex flex-col">
                <div className="btn-row flex flex-col">
                  <button
                    className="btn-add-propertiy button flex aic jc"
                    onClick={(e) =>
                      setAddProp([...addProp, { txt: { value: "" } }])
                    }
                  >
                    <div className="ico flex aic jc">
                      <PlusIcon />
                    </div>
                    <div className="btn-lbl">Add Propertiy</div>
                  </button>
                </div>
                {addProp.map((item, index) => (
                  <div className="row flex aic">
                    <div className="row-txt flex flex-col">
                      {/* <div className="f-lbl">erc20_contract-address</div> */}
                      <input
                        type="text"
                        className="txt cleanbtn"
                        placeholder="erc20_contract-address"
                      />
                    </div>
                    <div className="row-btn flex aic jc">
                      <div className="btn-remove button">Remove</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="action flex aic justify-end">
            <div className="btn button" onClick={handleUpdateCurrency}>
              Update Currency
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCurrency;
